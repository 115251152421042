import SelectField from '@/components/atoms/SelectField'
import { makeStyles } from '@material-ui/core'
import React, { SetStateAction, useState } from 'react'
const monthOptions = Array.from({ length: 12 }, (_, i) => i + 1).map((i) => ({ id: i, value: `${i}月` }))

const useStyle = makeStyles({
    button: {
        minWidth: 20,
        marginLeft: 20,
    },
    select: {
        borderRadius: '4px',
    },
})

function MonthSelect(props: {
    initialValue: string | number
    setValue: React.Dispatch<SetStateAction<string | number>>
}) {
    const classes = useStyle()
    const [value, setValue] = useState<string | number>(props.initialValue)
    return (
        <SelectField
            className={classes.select}
            fullWidth
            size="sm"
            menuItems={monthOptions}
            value={value}
            onChange={(e) => {
                const value = e.target.value as string | number
                props.setValue(value)
                setValue(value)
            }}
        />
    )
}

export default MonthSelect
