import theme from '@/theme'
import { InputLabel, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: theme.colors.white,
        },
        '& input': {
            padding: '10px',
            borderRadius: 4,
        },
        // number arrowsを削除
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::WebkitOuterSpinButton': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::WebkitInnerSpinButton': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    erorr: {
        '& input': {
            border: `solid 1px ${theme.colors.error}`,
        },
    },
    label: {
        fontSize: 14,
        fontWeight: 600,
        color: '#000',
        marginBottom: 5,
    },
})

export default function InputField({
    inputLabel,
    className,
    style,
    ...props
}: TextFieldProps & { inputLabel?: string }) {
    const classes = useStyle()

    return (
        <div>
            {inputLabel && <InputLabel classes={{ root: classes.label }}>{inputLabel}</InputLabel>}
            <TextField
                {...props}
                fullWidth
                variant="outlined"
                type="text"
                className={`${className} ${classes.root} ${props.error ? classes.erorr : ''}`}
            />
        </div>
    )
}
